import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/shopping",
    component: () => import("../pages/layout/Layout"),
    // 路由元信息 用于校验
    meta: {
      isLogin: true,
    },
    // 嵌套路由 在需要展示子组件的地方写 <router-view/>
    children: [
      {
        path: "/shopping",
        name: "Home",
        component: () => import("../pages/myHome/MyHome"),
      },
      {
        path: "/goods",
        name: "Goods",
        component: () => import("../pages/myGoods/MyGoods"),
        redirect: "/goods/list",
        children: [
          {
            path: "list",
            component: () => import("../pages/myGoods/GoodsList/index"),
          },
          {
            path: "analyse",
            component: () => import("../pages/myGoods/GoodsAnalyse/index"),
          },
          {
            path: "details",
            component: () => import("../pages/myGoods/GoodsDetails/index"),
          },
          {
            path: "audio",
            component: () => import("../pages/myGoods/GoodsAudio/index"),
          },
        ],
      },
      {
        path: "/class",
        name: "Class",
        component: () => import("../pages/myClass/MyClass"),
        redirect: "/class/classList",
        children: [
          {
            path: "classList",
            component: () => import("../pages/myClass/ClassList/index"),
          },
          {
            path: "studentList",
            component: () => import("../pages/myClass/StudentList/index"),
          },
          {
            path: "classDetails",
            component: () => import("../pages/myClass/ClassDetails/index"),
          },
        ],
      },
      {
        path: "/chain",
        name: "Chain",
        component: () => import("../pages/myChain/MyChain"),
        redirect: "/chain/list",
        children: [
          {
            path: "list",
            component: () => import("../pages/myChain/ChainList/index"),
          },
          {
            path: "details",
            component: () => import("../pages/myChain/ChainDetails/index"),
          },
        ],
      },
      {
        path: "/order",
        name: "Order",
        component: () => import("../pages/myOrder/MyOrder"),
        redirect: "/order/list",
        children: [
          {
            path: "list",
            component: () => import("../pages/myOrder/OrderList/index"),
          },
          {
            path: "insert",
            component: () => import("../pages/myOrder/OrderInsert/index"),
          },
        ],
      },
      {
        path: "/user",
        name: "User",
        component: () => import("../pages/myUser/MyUser"),
        redirect: "/user/list",
        children: [
          {
            path: "list",
            component: () => import("../pages/myUser/UserList/index"),
          },
          {
            path: "details",
            component: () => import("../pages/myUser/UserDetails/index"),
          },
          {
            path: "jurisdiction",
            component: () => import("../pages/myUser/JurisdictionList/index"),
          },
          {
            path: "jurisdictionDetails",
            component: () =>
              import("../pages/myUser/JurisdictionDetails/index"),
          },
        ],
      },
      {
        path: "/market",
        name: "Market",
        component: () => import("../pages/myMarket/MyMarket"),
        redirect: "/market/coupon",
        children: [
          {
            path: "coupon",
            component: () => import("../pages/myMarket/MarketList/index"),
          },
          {
            path: "details",
            component: () => import("../pages/myMarket/CouponDetails/index"),
          },
          {
            path: "group",
            component: () => import("../pages/myMarket/GroupList/index"),
          },
          {
            path: "packageDetails",
            component: () => import("../pages/myMarket/PackageDetails/index"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../pages/login/Login"),
  },
  {
    path: "/aiLogin",
    name: "AiLogin",
    component: () => import("../pages/aiLogin/AiLogin"),
  },
  {
    path: "/aiRegister",
    name: "AiRegister",
    component: () => import("../pages/aiRegister/AiRegister"),
  },
  {
    path: "/aiAdminLogin",
    name: "AiAdminLogin",
    component: () => import("../pages/aiAdminLogin/AiAdminLogin"),
  },
  {
    path: "/aiHome",
    component: () => import("../pages/chatGPTAdmin/ChatGPTAdmin.vue"),
    meta: {
      isLogin: false,
      isAiLogin: true,
    },
    children: [
      {
        path: "/aiHome",
        name: "AiHome",
        component: () => import("../pages/chatGPTAdmin/myHome/MyHome.vue"),
      },
      {
        path: "/aiChain",
        name: "AiChain",
        component: () => import("../pages/chatGPTAdmin/myChain/MyChain.vue"),
        redirect: "/aiChain/list",
        children: [
          {
            path: "list",
            name: "List",
            component: () =>
              import("../pages/chatGPTAdmin/myChain/ChainList/index.vue"),
          },
          {
            path: "details",
            name: "Details",
            component: () =>
              import("../pages/chatGPTAdmin/myChain/ChainDetails/index.vue"),
          },
        ],
      },
      {
        path: "/aiUser",
        name: "AiUser",
        component: () => import("../pages/chatGPTAdmin/myUser/MyUser.vue"),
        redirect: "/aiUser/list",
        children: [
          {
            path: "list",
            name: "List",
            component: () =>
              import("../pages/chatGPTAdmin/myUser/UserList/index.vue"),
          },
          {
            path: "details",
            name: "Details",
            component: () =>
              import("../pages/chatGPTAdmin/myUser/UserDetails/index.vue"),
          },
        ],
      },
      {
        path: "/aiClass",
        name: "AiClass",
        component: () =>
          import("../pages/chatGPTAdmin/adminClass/AdminClass.vue"),
        redirect: "/aiClass/class",
        children: [
          {
            path: "class",
            name: "Class",
            component: () =>
              import("../pages/chatGPTAdmin/adminClass/ClassList/index.vue"),
          },
          {
            path: "job",
            name: "Job",
            component: () =>
              import("../pages/chatGPTAdmin/adminClass/JobList/index.vue"),
          },
          {
            path: "jobLibrary",
            name: "JobLibrary",
            component: () =>
              import(
                "../pages/chatGPTAdmin/adminClass/JobList/JobLibrary/index.vue"
              ),
          },
          {
            path: "jobCreact",
            name: "JobCreact",
            component: () =>
              import(
                "../pages/chatGPTAdmin/adminClass/JobList/JobCreact/index.vue"
              ),
          },
          {
            path: "examination",
            name: "Examination",
            component: () =>
              import(
                "../pages/chatGPTAdmin/adminClass/ExaminationList/index.vue"
              ),
          },
        ],
      },
      {
        path: "/aiCompetition",
        name: "AiCompetition",
        component: () =>
          import("../pages/chatGPTAdmin/myCompetition/MyCompetition.vue"),
        redirect: "/aiCompetition/list",
        children: [
          {
            path: "list",
            name: "List",
            component: () =>
              import(
                "../pages/chatGPTAdmin/myCompetition/ResumeList/index.vue"
              ),
          },
          {
            path: "details",
            name: "Details",
            component: () =>
              import(
                "../pages/chatGPTAdmin/myCompetition/ResumeDetails/index.vue"
              ),
          },
        ],
      },
      {
        path: "/aiAuthenticationList",
        name: "AiAuthenticationList",
        component: () =>
          import(
            "../pages/chatGPTAdmin/authenticationList/AuthenticationList.vue"
          ),
        // redirect: "/aiSuperAdmin/authenticationList",
        // children: [
        //   {
        //     path: "authenticationList",
        //     name: "AuthenticationList",

        //     component: () =>
        //       import(
        //         "../pages/chatGPTAdmin/authenticationList/AuthenticationList.vue"
        //       ),
        //   },
        // ],
      },
    ],
  },
  {
    path: "/",
    name: "ChatGPT",
    // 路由元信息 用于校验
    meta: {
      isLogin: false,
      isAiLogin: true,
    },
    component: () => import("../pages/chatGPT/ChatGPT"),
    children: [
      {
        path: "/privateChat",
        name: "PrivateChat",
        component: () => import("../pages/chatGPT/PrivateChat/index.vue"),
      },
      {
        path: "/courseChat",
        name: "CourseChat",
        component: () => import("../pages/chatGPT/CourseChat/index.vue"),
      },
      {
        path: "/classWork",
        name: "ClassWork",
        component: () => import("../pages/chatGPT/ClassWork/index.vue"),
      },
      {
        path: "/classWorkDetail",
        name: "ClassWorkDetail",
        component: () => import("../pages/chatGPT/ClassWork/Detail/index.vue"),
      },
      {
        path: "/courseContent",
        name: "CourseContent",
        component: () => import("../pages/chatGPT/CourseContent/index.vue"),
      },
      {
        path: "/interviewChat",
        name: "InterviewChat",
        component: () => import("../pages/chatGPT/InterviewChat/index.vue"),
      },
      {
        path: "/interviewDialogue",
        name: "InterviewDialogue",
        component: () => import("../pages/chatGPT/InterviewDialogue/index.vue"),
      },
      {
        path: "/questionnaire",
        name: "Questionnaire",
        component: () => import("../pages/chatGPT/Questionnaire/index.vue"),
      },
      {
        path: "/questionResult",
        name: "QuestionResult",
        component: () => import("../pages/chatGPT/QuestionResult/index.vue"),
      },
      {
        path: "/questionResultTarget",
        name: "QuestionResultTarget",
        component: () =>
          import("../pages/chatGPT/QuestionResultTarget/index.vue"),
      },
      {
        path: "/questionResultTargetScore",
        name: "QuestionResultTargetScore",
        component: () =>
          import("../pages/chatGPT/QuestionResultTargetScore/index.vue"),
      },
      {
        path: "/interviewHistory",
        name: "InterviewHistory",
        component: () => import("../pages/chatGPT/InterviewHistory/index.vue"),
      },
      {
        path: "/questionResultOverall",
        name: "QuestionResultOverall",
        component: () =>
          import("../pages/chatGPT/QuestionResultOverall/index.vue"),
      },
      {
        path: "/counselorChat",
        name: "CounselorChat",
        component: () => import("../pages/chatGPT/CounselorChat/index.vue"),
      },
      {
        path: "/resumeContest",
        name: "ResumeContest",
        component: () =>
          import("../pages/chatGPT/ResumeContest/ResumeDetail/index.vue"),
      },

      // {
      //   path: "/resumeContest",
      //   name: "ResumeContest",
      //   component: () =>
      //     import("../pages/chatGPT/ResumeContest/ResumeList/index.vue"),
      //   // redirect: "/resumeContest",
      //   children: [
      //     // {
      //     //   path: "list",
      //     //   name: "List",
      //     //   component: () =>
      //     //     import("../pages/chatGPT/ResumeContest/ResumeList/index.vue"),
      //     // },
      //     {
      //       path: "details",
      //       name: "Details",
      //       component: () =>
      //         import("../pages/chatGPT/ResumeContest/ResumeDetail/index.vue"),
      //     },
      //   ],
      // },
      {
        path: "/resumeContestApply",
        name: "ResumeContestApply",
        component: () =>
          import("../pages/chatGPT/ResumeContestApply/index.vue"),
      },
      {
        path: "/resumeContestPreview",
        name: "ResumeContestPreview",
        component: () =>
          import("../pages/chatGPT/ResumeContestPreview/index.vue"),
      },
      {
        path: "/personal",
        name: "Personal",
        component: () => import("../pages/chatGPT/Personal/index.vue"),
      },
      {
        path: "/personalFile",
        name: "PersonalFile",
        component: () => import("../pages/chatGPT/PersonalFile/index.vue"),
      },
      {
        path: "/proveEnterprise",
        name: "ProveEnterprise",
        component: () => import("../pages/chatGPT/ProveEnterprise/index.vue"),
      },
      {
        path: "/proveSchool",
        name: "ProveSchool",
        component: () => import("../pages/chatGPT/ProveSchool/index.vue"),
      },
      {
        path: "/firmOption",
        name: "FirmOption",
        component: () =>
          import("../pages/chatGPT/WorkFirm/FirmOption/index.vue"),
      },
      {
        path: "/workFirm",
        name: "WorkFirm",
        component: () => import("../pages/chatGPT/WorkFirm/index.vue"),
        redirect: "/workFirm/firmManagement",
        children: [
          {
            path: "firmManagement",
            name: "FirmManagement",
            component: () =>
              import(
                "../pages/chatGPT/WorkFirm/children/FirmManagement/index.vue"
              ),
          },
          {
            path: "firmRecommend",
            name: "FirmRecommend",
            component: () =>
              import(
                "../pages/chatGPT/WorkFirm/children/FirmRecommend/index.vue"
              ),
          },
          {
            path: "firmChat",
            name: "FirmChat",
            component: () =>
              import("../pages/chatGPT/WorkFirm/children/FirmChat/index.vue"),
          },
          {
            path: "addManagement",
            name: "addManagement",
            component: () =>
              import("../pages/chatGPT/WorkFirm/addManagement/index.vue"),
          },
        ],
      },
      {
        path: "/workSchool",
        name: "WorkSchool",
        component: () => import("../pages/chatGPT/WorkSchool/index.vue"),
      },
      {
        path: "/workSchoolRecommend",
        name: "WorkSchoolRecommend",
        component: () =>
          import("../pages/chatGPT/WorkSchoolRecommend/index.vue"),
      },
      {
        path: "/jobDetail",
        name: "JobDetail",
        component: () => import("../pages/chatGPT/JobDetail/index.vue"),
      },
      {
        path: "/workChat",
        name: "WorkChat",
        component: () => import("../pages/chatGPT/WorkChat/index.vue"),
      },
      {
        path: "/companyDetail",
        name: "CompanyDetail",
        component: () => import("../pages/chatGPT/CompanyDetail/index.vue"),
      },
    ],
  },
  {
    path: "/exam",
    name: "Exam",
    component: () => import("../pages/exam/Exam"),
    meta: {
      isLogin: false,
      isAiLogin: true,
    },
    redirect: "/exam/exam",
    children: [
      {
        path: "exam",
        component: () => import("../pages/exam/Exam/index"),
      },
      {
        path: "homework",
        component: () => import("../pages/exam/Homework/index"),
      },
      {
        path: "classify",
        component: () => import("../pages/exam/Classify/index"),
      },
      {
        path: "result",
        component: () => import("../pages/exam/Result/index"),
      },
    ],
  },
  {
    path: "/friendsCircle",
    name: "FriendsCircle",
    component: () => import("../pages/friendsCircle/FriendsCircle.vue"),
    meta: {
      isLogin: false,
      isAiLogin: true,
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../pages/test/test.vue"),
    meta: {
      isLogin: false,
      isAiLogin: true,
    },
  },
  {
    path: "/test2",
    name: "test2",
    component: () => import("../pages/test/test2.vue"),
    meta: {
      isLogin: false,
      isAiLogin: true,
    },
  },
];

const router = new Router({
  routes,
});

import store from "../store/index";
// 路由拦截
router.beforeEach((to, from, next) => {
  // 遍历数组 满足条件返回true
  if (to.matched.some((record) => record.meta.isLogin)) {
    // 判断需要登录
    let token = store.state.m_login.loginData.token;
    if (token) {
      next();
    } else {
      next("/login");
    }
  } else if (to.matched.some((record) => record.meta.isAiLogin)) {
    // 判断需要登录
    let token = store.state.m_login.loginData.token;
    if (token) {
      next();
    } else {
      next("/aiLogin");
    }
  } else {
    next();
  }
});

export default router;
