export default {
    // 开启命名空间
    namespaced: true,

    // 存放共享的数据
    state: () => ({
        loginData: {}
    }),
    // 操作state中的数据
    mutations: {
        // 设置用户登录信息
        setUser (state, data) {
            state.loginData = data
        },
        // 清空
        clearUser (state) {
            state.loginData = {}
        }
    },
    // 接受用户的事件  调用它是异步的操作
    actions: {

    },
    // 类似计算属性
    getters: {

    }
}