import Vue from "vue";
import Vuex from 'vuex'
import loginModule from './modules/loginModule'

Vue.use(Vuex)

// 创建store实例对象
const store = new Vuex.Store({
    state: {

    },
    mutations: {

    },
    actions: {

    },
    // 注册组件
    modules: {
        'm_login': loginModule
    }
})

export default store