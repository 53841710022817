import Vue from 'vue'
import VueI18n from 'vue-i18n'

import chinese from './chinese'
import english from './english'

Vue.use(VueI18n)

// 准备翻译的语言环境信息
const messages = {
  en: {
    ...english
  },
  zh: {
    ...chinese
  }
}

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: 'zh',  // 选中的语言
  messages,  // 语言环境
})

export default i18n